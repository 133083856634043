
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
// some css from Bootstrap 4
@import "b4-mp";
// Font-awesome
@import "node_modules/font-awesome/scss/font-awesome.scss";

// Card
@import "node_modules/bootstrap-3-card/sass/_card.scss";

//Sweetalert
@import "node_modules/sweetalert/dev/sweetalert.scss";
@import "node_modules/sweetalert/themes/facebook/facebook.scss";
@import "dataTables.bootstrap";
@import "node_modules/select2/src/scss/core.scss";
@import "cookieconsent";
//main css file.
@import "main";