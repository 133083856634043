body, html{
    height: 100%;
}

#wrap {
    min-height: 100%;
    margin-bottom:  -255px;
}
#push-footer {    
    height: 255px;
}

#content{
    border: 1px solid #e3e3e3;
    padding-bottom: 20px;
}
h3{
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1em;
    letter-spacing: 0.025em;
}
h4{
    color: #2C3E50;
}

h5{
    color: $brand-institution-dark;
    margin-top: 0px;
    margin-bottom: 0px;
}

.margin-top-5{
    margin-top: 5px;
}

.btn-xs{
    margin-top: 4px;
}

/*--- NavBar ELEMENTS-----*/

.navbar-base {
    min-height: 40px !important;
    background-color: #ffffff;    
}
.navbar-toggle {
    margin-top: 3px;
    margin-bottom: 3px;
}
.navbar-brand {
    background: url(../img/auth_logo_navbar.png) no-repeat center center;
    background-size: 200px 38px;
    height: 40px !important;
    width: 202px;
}
.navbar {
    margin-bottom: 0;
}
@media (min-width: 992px) {
    #main-content {
        padding-top: 60px;
    }
}
@media (max-width: 991px) {
    #main-content {
        padding-top: 19px;
    }
}
#main-content {
    padding-bottom: 20px;
}
@media (max-width: 991px) {
    .navbar-fixed-top { position: static; }
    .navbar-brand { margin-right: 90px;}
}

@media (max-width: 991px) and (min-width: 768px) {
    .navbar-base .container, #main-content {
        width: auto;
        margin-left: 15px;
        margin-right: 15px;
    }
}
#main-content {
    margin-left: 15px;
    margin-right: 15px;
}

@media (min-width: 768px) {
    .filter { float:right;}
}

@media (max-width: 767px) {
    .navbar-brand { margin-left: 15px;}
}
.navbar-brand {
    margin-right: 40px;
}
#navBarAll>.navbar-nav>li>a {
    padding: 10px;
}
/*-----END HEADER--------*/
/*-----SideBar ELEMENTS--------*/
@media (min-width: 768px) {
    .row > .sideBar { padding-left: 0;}
}

@media (min-width: 1200px) {
    .row > .sideBar { padding-left: 0;}
}

.nav-header {
    display: block;
    padding: 3px 15px;
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
    color: #999999;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
}

#sideBar, .nav-custom {
    padding: 10px 0px;
}

#sideBar a {
    color: #2C3E50;
    font-weight: bold;
    padding: 7px 14px;
}

.nav-custom a {
    padding: 5px 14px !important;
}

#sideBar .active a {
    background-color: #2C3E50;
    color: #ffffff;
}

.nav-custom .active a, #sideBar .active a {
    font-weight: bold;
    margin-left: -5px;
    margin-right: -5px;
}
/*-----END SideBar--------*/


/*--- FOOTER ELEMENTS---*/
/*Footer
------------------------------------*/
.footer {
    padding-top: 10px;
    background: #272727;
    color: #BDC3C7;
    line-height: 23px;
    min-height: 200px;
}
.headline {
    display: block;
    margin: 10px 0 25px 0;
    border-bottom: 1px dotted #BDC3C7;
}
.footer h2 {
    font-size: 18px;
    font-weight: 200;
    margin: 0 0 -2px 0;
    padding-bottom: 5px;
    display: inline-block;
    border-bottom: 2px solid $brand-institution-light;
}

.footer a {
    color: #BDC3C7;
}
.footer-logo {
    margin: 17px 0 20px;
    height: 45px;
}
.footer ul li {
    padding-bottom: 5px;
}

.link-list li i {
    float: right;
    margin-top: 10px;

}
.link-list .link-list li {
    border-top: solid 1px #353535;
}
.link-list li:first-child {
    border-top: none !important;
}
.link-list li {
    border-top: solid 1px #353535;

}
/*END Footer
------------------------------------*/
/*Copyright*/
.copyright {
    background: #222;
    border-top: none;
    padding: 5px 0 5px;
    min-height: 55px;
}

.copyright p {
    font-size: 13px;
    color: #777;
    margin-top: 14px;
}

.copyright a {
    margin: 0 2px;
    color: $brand-institution-light;
}

.footer-socials {
    margin-top: 12px;
    text-align: right;
}

.footer-socials li a {
    color: #777;
    font-size: 16px;
}
/*END Copyright*/

@media (max-width: 767px) {
    #wrap {
        margin-bottom:  -530px;
    }
    #push-footer {
        height: 530px;
    }
    .footer {
        min-height: 475px;
    }
}

/*-----END FOOTER------------*/
/*-----Start Login-----------*/
.federatedImage > img {
    width: 80%;
}
@media (max-width: 767px) {
    #local-signin button, #pass-recovery button, #register button { margin-left: 15px !important;}
}
@media (min-width: 768px) {
    #pass-recovery button, #register button, #local-signin button { float: right; margin-right: 15px;}
}
.alert-error {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
} 

.register-error {
    border-color: #a94442 !important;
    color: #a94442;
}

.register-success {
    border-color: #3c763d !important;
    color: #3c763d;
}

.help-block ul{
    padding: 0; 
}    
.help-block li{
    list-style: none;
    color: #e74c3c;
    letter-spacing: 0.04em; 
}

#bon_success{
    color: #1abc9c;
    letter-spacing: 0.04em;
}
#bon_fail{
    color: #e74c3c;
    letter-spacing: 0.045em;
}
/*-----End Login-----------*/
/*-----Start Title-----------*/
.page-header { 
    margin-top: 0; 
    padding-bottom: 0; 
    margin-bottom: 10px; 
}
.page-header .tab-header{
    margin-top: 5px;
}
/*-----End Title-----------*/
/*-----Start Publications-----------*/
@media (min-width: 768px) {
    #publications_search .form-group { margin-bottom: 15px !important; }
    #publications_search .form-group .col-sm-2 { 
        padding: 0;
    }
    #publications_search .form-group .col-sm-8 { 
        padding-left: 2px;
    }
}
@media (max-width: 767px) {
    #publications_search button {
        margin-left: 15px;
        margin-right: 15px;
    }
}
@media (max-width: 1199px) {
    #publications_search .form-group .col-sm-2:last-child > select { 
        width: 100% !important;
    }
}
@media (min-width: 1200px) {
    #publications_search .form-group .col-sm-2:first-child { 
        width: 10.66666667%;
    }
    #publications_search .form-group .col-sm-8 { 
        width: 70.66666667%;
    }
}
.searchRemove {
    background: transparent;
    -webkit-appearance: none;
    border: none;
}
.searchRemove[disabled] > i {
    color: rgb(219, 169, 164);
    cursor: auto;
}
#searchResultsForm .page-header h3 {
    margin: 0;
}

#searchResultsForm .well {
    padding: 10px;
}
.refineHeader {
    background-color: rgb(226, 226, 226);
    margin-bottom: 5px;
}

.refineHeaderSearch {
    background-color: rgb(207, 207, 207) !important;
    margin-top: 10px !important;
}

.refineHeader:hover {
    cursor: pointer;
    text-decoration: none !important;
}

.refineHeader i {
    float: right;
    margin-top: 5px;
    margin-right: -5px;
}

.refineBody .form-group {
    padding-right: 0;
    padding-left: 0;
}

.refineBody .form-group > small > span{
    margin-top: 6px;
}

/*-----End Publications-----------*/
/*-----Start Publications Filters-----------
*/
.selectYear{
    width:auto;
    display:inline-block;
}
@media(max-width: 991px) {
    #validatePubs .pubAction {
        padding: 2px;
        margin-left: 30px;
    }

    #validatePubs .pubAction hr {
        display: block !important;
        margin: 5px;
        border-top: 1px solid #B4B4B4;
    }

    #validatePubs .pubAction .form-group {
        margin-bottom: 0;
        display: inline-block;
        margin-right: 15px;
    }
    #validatePubs .pubAction .radio, #validatePubs .pubAction select, #validatePubs .pubAction .checkbox {
        display: inline-block;
    }

    #validatePubs .pubAction .associated {
        width: auto !important;
    }

    #validatePubs .pubAction .checkbox {
        margin-left: 20px;
    }

    #validatePubs .pubAction .radio > input[type="radio"] {
        position:relative;
        margin-left: 0;
        //margin: 10px 0;
    }
}

.pubAction .select {
    margin-bottom: 0;
    font-size: 90%;
}

.pubAction {
    //padding-left: 0;
    padding-right: 0;
}
.associated {
    width: 100% !important;
    font-size: 12px;
    margin:0;
    height: 20px;
    padding: 0;
}


.pubAction .middle {
    vertical-align: middle;
}

.pubAction .bottom {
    vertical-align: text-bottom;
}
/*-----End Publications Filters-----------*/
/*-----Start Pagination-----------*/
.pagination {
    margin-bottom: 0px;
    margin-top: 0px;
}
.pagination-xs > li > a {
    padding: 2px 6px;
    font-size: 11px;
}

.pagination>.active>a, .pagination>.active>span, .pagination>.active>a:hover, .pagination>.active>span:hover, .pagination>.active>a:focus, .pagination>.active>span:focus {
    background-color: orange;
    border-color: orange;
}

/*-----End Pagination-----------*/

#journalDiv table tr td:first-child {
    text-align: left !important;
}
.translations table td {
    vertical-align: middle !important;
}

.controller td {
    background-color: #D0D0D0 !important;
    font-weight: bold;
    text-align: left !important;
}

.rowMainTask td:first-child {
    text-align: left !important;
}

.subtask td {
    padding: 0 !important;
    //vertical-align: middle;
    font-size: 13px;
}
.subtask td .btn {
    padding: 0 !important;
    font-size: 11px;
}

.subtask:nth-child(odd) > td {
    background-color: rgb(234, 240, 237);
}

.subtask:nth-child(even) > td {
    background-color: rgb(243, 243, 243);
}

.subpagination td .pagination  {
    margin: 0 !important;
}

.subtaskRetried  td {
    background-color: rgb(155, 167, 190) !important;
}
/*---End Responsive Tables------*/
/*----Start General Configs-----*/

.required:after {
    content: ' *';
    color:red;
}

#co_authors_graph .graph-frame, #instStats .highcharts-container {
    overflow: inherit !important;
}

@media (max-width: 767px) {
    .daemon-actions {
        display: block;
        width: 100%;
    }
    .daemon-actions > .daemon-actions-btn {
        float: right;
    }

    .search-query {
        width: 74% !important;
    }
}

#usersList .btn, #groupsList .btn, #articlesList .btn, #requestsList .btn, .margin-right {
    margin-right: 2.5px;
}

#usersList table tr td:last-child, #requestsList table tr td:last-child {
    min-width: 95px;
}

.treePath {
    margin: 5px 0px;
    padding: 5px 0px;
    background-color: rgb(235, 235, 235);
}
.treePath .dropdown-menu {
    min-width: 100px;
}

.dropdown-menu a:hover { cursor: pointer; }
/*----End General Configs-----*/


#requestsList .btn-xs{
    width: 150px;
    margin-bottom: 5px;
}



div.block {
    margin: 20px 0px;
}

div.block + div.block.inline {
    margin-top: 0;
    margin-bottom: 10px;
}

div.block.inline + div.block {
    margin-top: 10px;
}

div.block.inline + div.block.inline, div.block.inline + div.block-separator + div.block.inline {
    margin: 10px 0 !important;
}

div.block .inline + div.block-separator {
    display:none;
}



div.block.inline {
    display: inline-block;
}

.label-new[href] {
    background-color: #ffffff;
    font-size: 100%;
}
a.label-new{
    text-shadow:none;
    color: #3297da;
    border-right: 1px dotted  #3297da;
}

a.label-new:hover{
    color: #7F8C8D;
}

/*---RESEARCHER PROFILE----*/

ul .highlight:hover {
    background-color: #ECE9E9;
}

.list-unstyled li a i {
    vertical-align: top;
    margin-left: 5px;
}

.linkNoHighlight:hover {
    text-decoration: none;
}

.cell {
    display:table-cell;
}

.badge-new {
    display: inline;
    padding: 3px 4px;
    font-size: 10px;
    color: white !important;
}

.well{
    background-color: transparent;
    border-radius: 0;
}


#email-image{
    width: auto;
}

.auth-button{
    margin-top: 15px;
}

.hint-info{
    color: #999;
}
.hint-info-red{
    color: #E74C3C; 
}

/*---END OF RESEARCHER PROFILE----*/

/*INSTITUTIONS*/

.institution-structure{
    margin-top: 10px;
    padding-left: 0px;
}

.institution-structure li{
    padding:5px 5px 5px 35px;
    list-style: none;
    border: 1px solid #e7e7e7;
    background-color: #f5f5f5;
    margin-bottom: 10px;
    min-height: 50px;
}

.institution-structure li:before {
    content: "\f19c"; /* FontAwesome Unicode */
    font-family: FontAwesome;
    display: inline-block;
    margin-left: -23px; /* same as padding-left set on li */
    margin-right: 5px;
    color: #34495E;
}

.institution-structure li a{
    text-decoration: none;
    color: #34495E;
    font-weight: bolder;
}

.auth-alert{    
    color: #E74C3C;    
}

.institutions-accordion>div{
    padding: 5px 5px;
}

/*END INSTITUTIONS*/

.icon-side-bar {
    float: right;
    margin-top: 2px;
    margin-right: -5px;
}

.well-text, .well-text > ul > li, .well-text > ol > li {
    text-align:justify;
    line-height: 150%;
}

.well-text {
    background-color: #e5e5e5;
    border: 1px solid #c3c3c3;
}

.well-text > ol > li > a {
    color: black;
    font-weight: bold;
}

.well-text > ol > li > a:hover {
    cursor: pointer;
}

.well-head, .well-title {
    color: rgb(94, 92, 92);
}

.C-flags {
    padding: 8px 6px 8px !important;
}

.active .C-flags {
    background-color: #ffffff !important;
}

.navbar .divider-vertical {
    border-right: 1px solid #ccc !important;
}

.row_left {
    text-align: left !important;
}

.affiliations td, .affiliations th {
    text-align: left !important;
}

.table-users td {
    padding: 8px 1px !important;
}

.affiliations td, .affiliations th {
    vertical-align: middle;
    font-size: 13px;
}

.add-user, .add-group, .sync-acos, .add-article {
    margin-bottom: 30px;
}

.new-pass {
    color: rgb(92, 85, 85);
}

.alert-fixed {
    position: fixed;
    top: 10%;
}

#cy {
    height: 800px;
}

.div-odd .row:hover, .div-even .row:hover, .div-odd.row:hover, .div-even.row:hover {
    background-color: rgb(240,240,240);
}

.div-odd, .div-even, .div-odd-2, .div-even-2 {
    padding: 5px 5px;
}
.div-odd , .div-even, .div-odd-2, .div-even-2 {    
    /*    border-bottom: 1px solid #ECF0F1;*/
}

.div-even-2, .div-odd-2 {
    padding: 5px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    border: 1px solid;
    border-color: white;
}

.div-odd-2 {
    background-color: #f0f0f0;
}

.div-even-2 {
    background-color: rgb(235, 233, 233);
}

.row-odd {
    background-color: red !important;   
}

.row-even {
    background-color: green !important;
}

.div-even .title, .div-odd .title, .div-even .author, .div-odd .author, .linkTitle {
    text-decoration:underline;
    color: #34495E;
    font-weight: bolder;
}

.div-even .author, .div-odd .author {
    text-decoration:none;    
}
.title:hover {
    color:rgb(47, 127, 167) !important;
}

.author:hover{
    color:rgb(47, 127, 167) !important;
    border-bottom: 1px dotted #2C3E50;
}

.div-even img, .div-odd img {
    padding-bottom: 2px;
}

.comment {
    font-size: 11px;
    color: #999999;
}

#add-isi-pubs, #add-scopus-pubs, #add-dblp-pubs {
    background-color: rgb(252, 252, 252);
}

#accordion-add-pubs .accordion-heading {
    background-color: rgb(238, 238, 238);
}

.inlineImg {
    height: 13px !important;
    background-color: transparent;
}

.citText {
    color: rgb(129, 125, 125) !important;
    font-weight: bold;
    font-family: Monospace !important;
}

.inlineImgLinks {
    height: 16px !important;
    width: 16px !important;
}

.headSort {
    cursor: pointer;
}

.headSort:hover {
    text-decoration:underline;
    color:rgb(47, 127, 167);
    //font-size: 14px;
}

.button-submit {
    margin-top: 20px;
    text-align: center;
}

.CursorPointer:hover {
    cursor: pointer;
}

.printHeader > h5 {
    margin:0;
}

.print-print {
    position: fixed;
    right: 0;
    margin-right: 20px;
    cursor: pointer;
    color: #848585
}

.tasksProgress {
    margin-bottom: 0px !important;
}

.validate+.validate {
    margin-left: 5px !important;
}

.dashboard{
    background-color: #fff;
    margin-bottom: 0;    
    border-top-left-radius: 0;    
}

.dashboard .dashboard-header{
    background: linear-gradient(0deg, #fafafa 0%, #e9e9e9 100%);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid #e3e3e3;  
    padding: 18px;
}

.dashboard .dashboard-content{
    border: 1px solid #e3e3e3;  
    padding: 12px;
}

/*------------------------------------------------------------------
[ Shortcuts / .shortcuts ]
*/
.shortcuts {
    text-align: center;
}
.shortcuts .shortcut {
    width: 110px;
    display: inline-block;
    padding: 15px 0;
    margin: 0 5px 1em;
    vertical-align: top;
    text-decoration: none;
    background: #F3F3F3;
    background-image: linear-gradient(60deg, #ffffff 0%, #eeeeee 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffeeeeee', GradientType=0);
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 5px;
}
.shortcuts .shortcut .shortcut-icon {
    width: 100%;
    margin-top: .25em;
    margin-bottom: .35em;
    font-size: 32px;
    color: #555;
}
.shortcuts .shortcut:hover {
    background: #E8E8E8;
    background-image: linear-gradient(60deg, #fafafa 0%, #e1e1e1 100%);
    background-repeat: repeat-x;  
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffafafa', endColorstr='#ffe1e1e1', GradientType=0);
}
.shortcuts .shortcut:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.shortcuts .shortcut:hover .shortcut-icon {
    color: #666;
}
.shortcuts .shortcut-label {
    display: block;
    margin-top: .75em;
    font-weight: 400;
    color: #666;
}

.shortcuts a:hover {
    text-decoration: none;
}

.changecolor + .tooltip > .tooltip-inner {
    background-color: #fff; 
    color: #000; 
    border: 1px solid #CCCCCC; 
}

.changecolor + .tooltip.top > .tooltip-arrow{
    bottom:0;
    left:50%;
    margin-left:-5px;
    border-left:5px solid transparent;
    border-right:5px solid transparent;
    border-top:5px solid #CCC
}
.changecolor + .tooltip.left > .tooltip-arrow{
    top:50%;
    right:0;
    margin-top:-5px;
    border-top:5px solid transparent;
    border-bottom:5px solid transparent;
    border-left:5px solid #CCC;
}
.changecolor + .tooltip.bottom > .tooltip-arrow{
    top:0;
    left:50%;
    margin-left:-5px;
    border-left:5px solid transparent;
    border-right:5px solid transparent;
    border-bottom:5px solid #CCC;
}
.changecolor + .tooltip.right > .tooltip-arrow{
    top:50%;
    left:0;
    margin-top:-5px;
    border-top:5px solid transparent;
    border-bottom:5px solid transparent;
    border-right:5px solid #CCC;
}

.tooltip-icon{
    position: relative;
    left:115px;
    color: #0088cc;
}

.input-search-mini {
    width: 100%;
    height: 25px !important;
    font-size: 80%;
}

#co_authors_list, #science_fields_list, #venues_list, #subject_cat_list {
    max-height: 500px;
    overflow: auto;
}

ol.bullet-numbering {margin-left:0; padding-left:0; counter-reset:item}
ol.bullet-numbering>li {
    margin-left:0; padding-left:0; counter-increment:item; list-style:none inside;margin-bottom:10px;
    color:#3297da;
    font-weight: bold;
}
ol.bullet-numbering>li:before {
    content:counter(item);
    padding:3px 8px;
    margin-right:0.5em;
    border-radius: 13px; 
    border: 2px solid #3297da;
}

div.resultBox{       
    color:#C0392B;
}

.top-right-icon:before {
    position: relative;
    left: -2px;
    top: -3px;
}

.mail_notprocessed, .mail_processed, .mail_locked, .mailRead {
    width: 100%;
    padding: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid;
    margin-bottom: 2.5px;
}

.mail_notprocessed:hover, .mail_processed:hover, .mail_locked:hover {
    border: 1.5px solid;
}

.mailRead {
    background-color:rgba(231, 231, 231, 0.42);
    border: none;
}

.mail_notprocessed {
    background-color: lightgrey;
    border-color: grey;
}

.mail_processed {
    background-color: rgb(203, 236, 203);
    border-color: green;
}

.mail_locked {
    background-color: rgb(252, 239, 189);
    border-color: rgb(219, 181, 28);
}

.mail_locked .fa .fa-lock {
    color:rgb(219, 181, 28);
}

.emailActionBar {
    width: 100%;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    padding: 7px;
}

.mailList {
    color: black !important;
}

.mailList:hover {
    text-decoration: none;
}

.titleLink {
    color: #34495E !important;
    font-weight: bolder !important;
}

.pub_copy {
    border: 1px solid rgb(211, 103, 103) !important;
}

.pub_default_copy {
    border: 1px solid rgb(64, 179, 64) !important;
}

.userOnline td {
    background-color: rgb(180, 211, 180) !important;
}

.userNotActive td {
    background-color: rgb(245, 179, 179) !important;
}

.help-buttom {    
    text-decoration: inherit;
}

.help-buttom:hover {
    text-decoration: inherit;
}

.help-buttom:focus {
    text-decoration: inherit;
}

.row-limit {
    max-width: 250px;
}

#loading {
    width:100%;
    text-align: center;
}

.affiliation.accordion-heading{
    background-color: #e3e3e3;
}

.affiliations.accordion-inner {
    padding: 0;
}

.small-small {
    font-size: 75%;
}

.small-mini {
    font-size: 65%;
}

.hide-after-load.pagination-mini {
    margin: 0 !important;
}


.cake-sql-log {	
    top:99%;
    z-index:10;
    width:100%;
    background-color:#000;
    color:#FFF;
    border-collapse:collapse;
}

.cake-sql-log caption {
    background-color:#900;
    color:#FFF;	
}

.cake-sql-log td {
    padding:3px;
    border:1px solid #999;
    background-color:#EEE;
    color:#000;
}

.widget{    
    margin-bottom: 10px;
}
.innerAll {

    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #e3e3e3;
}
.innerAll-top{    
    min-height: 200px;
}

.widget i{
    font-size: 50px;   
}

.widget .btn{
    border-radius: 0px;
    color: white;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    text-shadow: none;
}

.widget .btn-inverse:hover{
    background-color: #fff;
    border: 1px solid #424242;
    color: #424242;
    transition: all 300ms linear;
}

.widget .bg-inverse, .widget .btn-inverse {
    background: #424242;
    color: #fff;
    border: 1px solid #fff;
}

.widget .bg-info , .widget .btn-info{
    background: #466baf;
    color: #fff;
    border: 1px solid #fff;
}

.widget .btn-info:hover{
    background-color: #fff;
    border: 1px solid #466baf;
    color: #466baf;
    transition: all 300ms linear;
}


.widget .bg-journal , .widget .btn-journal{
    background: #BDC3C7;
    color: #2C3E50;
}

.orcid-widget-img{
    width: 75%;
    height: auto;
}

.orcid-data{
    text-align: left;
    color: #939598;
}
.external-db{
    text-align: left;


}

.widget .bg-orcid {
    background: #ffffff;
    color: #A6CE39;
    border: 4px solid #A6CE39;
}

.widget .btn-orcid-noimg{
    border: 1px solid #A6CE39;
    color: #A6CE39;
}

.widget .btn-orcid-noimg:hover{
    background:#A6CE39;
    color:  #fff;
}

.widget .btn-journal:hover{
    background-color: #2C3E50;
    border: 1px solid #BDC3C7;
    color: #BDC3C7;
    transition: all 300ms linear;
}

.widget .bg-researcher , .widget .btn-researcher{
    background: $brand-institution-light;
    color: white;
    border: 1px solid #fff;
}

.widget .btn-researcher:hover{
    background-color: white;
    border: 1px solid $brand-institution-light;
    color: $brand-institution-light;
    transition: all 300ms linear;
}

.widget .bg-default , .widget .btn-default{
    background: #2C3E50;
    color: white;
}

.widget .btn-default:hover{
    background-color: white;
    border: 1px solid #2C3E50;
    color: #2C3E50;
    transition: all 300ms linear;
}
.widget-number{
    font-size: x-large;
}



/****************/
/**Publication view, header-links**/
/****************/
.content-header{
    min-height: 70px;
    border-bottom: 5px solid $brand-institution-light;
}

.content-header h4{
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1em;
    letter-spacing: 0.025em;
}

.content-header h4 > i{
    padding-right: 15px;
    color: $brand-institution-light;
}

.user-header{
    border-color: $authenticus-dark-blue;
}

.user-header h4 > i{
    color: $authenticus-dark-blue;
}

.custom_group_header{
    border-color: #aec785;
}

.publication-header{
    border-color: #BDC3C7;
}

.institution-header{
    padding: 0px 0px;
    border-bottom: 5px solid $brand-institution-dark;
}

.action-box {
    background-color: $brand-institution-light;
    padding: 1.25rem;
    border-top: 1px solid rgba(0,0,0,.125);
    border-bottom: 1px solid $brand-institution-dark;
}

.header-links{
    padding: 5px 10px 5px 0;
    display: inline-block;
}

.header-links a{
    margin: 3px 0;
}

.researcher-box, .institution-box{
    background-color: $brand-institution-dark;
    padding:  0px 20px;
}

.institution-box {
    border-bottom: 5px solid $brand-institution-dark;
}

.no-border{
    border: 0px; 
}







.btn-orcid{
    background: url('../img/orcid_icon.png') 3px  2px no-repeat; 
    background-size: 18px 18px;
    padding: 2px 5px 2px 25px;
    font-weight: 700;    
}

.orcid-green, .orcid-green:hover{
    color: #8AB21B;
    background-color: white;
    border-color: #a8d039;
    cursor: default;  
}

.orcid-red, .orcid-red:focus{
    color: #e74c3c;
    background-color: white;
    border-color: #e74c3c;
}
.orcid-red:hover{
    color: white;
    background-color: #e74c3c;
    border-color: #e74c3c;
}

#body_orcid_syncronization{
    border-top: 3px solid #a6ce39;
    background-color: #fbfdf6;
}
.orcid_color {
    color: #a6ce39!important;
}
.label-orcid {
    background-color: #a6ce39;
}
.btn-orcid-simple{
    background-color: #a6ce39;
    border: 1px solid #a6ce39;
    color: #ffffff;
}
.btn-orcid-simple:hover{
    background-color: #ffffff;
    border: 1px solid #a6ce39;
    color: #a6ce39;
}
.material-switch > input[type="checkbox"] {
    display: none;
}

.material-switch > label {
    cursor: pointer;
    height: 0px;
    position: relative;
    width: 40px;
}

.material-switch > label::before {
    background: rgb(0, 0, 0);
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position:absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 40px;
}
.material-switch > label::after {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 24px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 24px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.5;
}
.material-switch > input[type="checkbox"]:checked + label::after {
    background: inherit;
    left: 20px;
}

/***********************/
/**Source tabs***/
/***********************/

.sourceTab{

}

.nav-tabs>li>a.source-tab-red{
    font-weight: bold;
    border: none;
    padding:8px 10px;
    color: #fff;
    background-color: #e74c3c;
}
.nav-tabs>li>a.source-tab-green, .nav-tabs>li.active>a.source-tab-green{
    font-weight: bold;
    border: none;
    padding: 8px 10px;
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
}

/***********************/
/**Authenticus Lables***/
/***********************/
.auth-link a, .media-header, .tab-header, .page-header > h2 {           
    font-weight: 600;
}

tr.thead{
    background-color: transparent;

}

#body_affil_FCT > div > table > tbody>tr>td, #body_affil_publication > div > table > tbody>tr>td ,
#body_affil_other > div > table > tbody>tr>td, #body_affil_RB > div > table > tbody>tr>td {
    border: none;
}

.auth-label  {
    color:#2C3E50;
    padding-top: 10px;
    font-weight: 600;
}

.auth-label i, .auth-label-warning i, .auth-label-success i{
    padding-right: 5px;
}

.auth-label-warning{
    padding-top: 10px;
    font-weight: 600;
}
.auth-label-success{
    padding-top: 10px;
    font-weight: 600;
}
.description{
    padding-top: 10px;
}

.label-keys {
    text-transform: uppercase;
    color: #428bca;
}
.auth-label-small {
    color: #3297da;
    text-shadow:none;
    font-weight: bold;
}

.auth-label-small-warning {
    color: #e74c3c;
    text-shadow:none;
    font-weight: bold;
}


.input-group[class*="col-"]{
    padding-left: 15px;
}


/****************/
/**Institution views**/
/****************/
#navBarUser .bon-image{
    height:1.5em;
    position:absolute;
    right:10px;
    width: auto;
}

.bon-image{
    float: right;
    width: 55px;
    height: auto;
}
.institution-categories{
    font-size: 15px;
    font-weight: 500;
    color: #2C3E50;
    margin-bottom: 10px;    
    margin-top: 15px;
    text-transform: uppercase;    
}

.button-authenticus {
    margin-top: 10px;
    background-color: white;    
    color: #2C3E50;
    border: 1px solid $brand-institution-light;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.button-authenticus:hover, .button-authenticus:active,  .button-authenticus.selected{
    background-color: $brand-institution-dark;
    color: #2C3E50 !important;
}

.arrow_box {    
    display: inline;
    padding:8px 15px;    
    background-color: $brand-institution-dark;
    margin-right: 15px;
    border-radius: 25px;
}
.custom_group_theme { 
    border-color: #aec785;
    color: #fff;
    background-color: #aec785;
}


.institution-categories i{
    padding: 0 10px;
}
.profile-window {
    padding: 10px; 
}

.researcher-profile{
    background-color:#c5d1e6;
    color: #2C3E50;
    vertical-align: bottom;
}

.researcher-profile h4{
    margin: 10px 0 0 0;       
}

.profile-icon i{
    font-size: 80px;    
    padding: 10px 0 0 15px;
}

.instiution-profile{
    background-color: #2C3E50;
    color:$brand-institution-dark;
}

.group-profile{
    background-color: #aec785;
    color:#688636;
}

.instiution-profile i, .group-profile i{
    font-size: 95px;
    padding:10px 20px;
}

div#scientific-areas-list div {
    border: 1px solid #CCC;
    cursor: move;    
    margin-bottom: 3px;
    line-height: 23px;
    padding: 5px 5px 5px 15px;
}
#scientific-areas-list{
    padding: 0;
    border: none;
}
#scientificAreaTab > div.panel{
    border: none;
    box-shadow: none;
}

div#scientific-areas-list i {
    padding: 5px;
}

#ecd_opt_in_banner {
    margin-top: 42px;  
    width: 100%;
    z-index: 99999;
    background-color: #E8EEF9;
}
#ecd_opt_in_banner div {
    padding: 15px;
    border: 2px solid $brand-institution-light;
    text-align: center;
}

#content > div.well{
    border: 0px;
}


/**
User Dashboard Item
*/
.user-dashboard-item{
    min-height: 80px;
    margin: 5px;
    padding: 25px 0;
}

/**
User Groups Item
*/
.user-group-item{
    min-height: 130px;
    margin: 5px 0  5px 5px;
    padding: 25px;
    background-color: #637EAF;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, .05);
}

.user-group-links{
    margin: 5px 5px 5px 0;
    padding: 0 0 0 20px;
    min-height: 130px;
    border: 1px solid rgba(0, 0, 0, .1);
    font-size: small;
}
.user-group-links ul{
    padding: 0;
}
.user-group-links ul li{
    list-style: none;
    padding: 3px 0;
}

.user-group-links .tooltip-inner{   
    text-align: left;    
}
.user-group-links .auth-label{
    padding-top: 0;
    font-size: small;
}
.user-group-item.researcher{
    background-color: rgb(197, 209, 230);    
}
.user-group-item.researcher a{
    color: rgb(44, 62, 80) !important;
}
.user-group-item.custom_group{
    background-color: #34495e;
}

.user-group-item i {
    display: block;
    font-size: 25px;
    padding-bottom: 10px;
}
.user-group-item a {
    font-size: 15px;
    text-decoration: none;
    font-weight: bold;
}

.groups-header h4{
    font-weight: bold;
}
.groups-header i {
    margin-right: 10px;
}
#mergeRequestsList{
    display: inline-table;
    margin-top: 30px;
    width: 100%;
}

.text-darkred {
    color: darkred;
}


/***
**** Researcher Association
****/
.authenticus-tabs .nav-tabs > li, .authenticus-tabs .nav-pills > li {
    margin: 10px 0 0 0;    
}
.authenticus-tabs .nav-tabs {
    border-bottom: none;   
}

.authenticus-tabs .nav-tabs>li>a{
    font-size: 15px;
    font-weight: bold;
    background: white;
    color: #2C3E50;
    border: 1px solid #2C3E50;
    padding: 7px 14px;
    white-space: nowrap;
    border-radius: 4px;
}

.authenticus-tabs .nav>li>a:hover, .authenticus-tabs .nav>li>a:focus{
    background: #2C3E50;
    border: 2px solid #2C3E50;
    color: white;
}
.authenticus-tabs .nav-tabs>li.active>a, .authenticus-tabs .nav-tabs>li.active>a:focus,.authenticus-tabs .nav-tabs>li.active>a:hover{
    background: #2C3E50;
    border: 2px solid #2C3E50;
    color: white;
}
.authenticus-tabs .tab-pane{
    min-height: 200px;

}
.authenticus-tabs .tab-pane.active{
    background: #f5f5f5;
}

.authenticus-tabs .header{
    font-size: 18px;
    font-weight: bold;
}

/* Custom Colored Panels */
.user-groups-index .panel, #associated .panel {
    border-radius: 0;
    margin-bottom: 20px;
}
.user-groups-index .panel-heading, #associated .panel-heading{
    border-radius: 0;
    min-height:100px; }
.panel-footer ul{
    padding-left: 0;
    margin: 0;
}
.panel-footer ul li{
    display: inline-block;
    list-style:  none;
    padding: 2px 5px;
}

.panel-blue {
    border-color: #aec785;
}

.panel-blue > .panel-heading {
    border-color: #aec785;
    color: #688636;
    background-color: #aec785;
}

.panel-blue > a {
    color: #aec785;
}

.panel-blue > a:hover {
    color: #2C3E50;
}

.panel-darkblue {
    border-color: #2C3E50
}

.panel-darkblue > .panel-heading {
    border-color: #2C3E50;
    color: #fff;
    background-color: #2C3E50;
}

.panel-darkblue > a {
    color: #2C3E50;
}

.panel-darkblue > a:hover {
    color: $brand-institution-light;
}

.panel-grey {
    border-color: #BDC3C7;
}

.panel-grey > .panel-heading {
    border-color: #BDC3C7;
    color: #2C3E50;
    background-color: #BDC3C7;
}

.panel-grey > a {
    color: #2C3E50;
}

.panel-grey > a:hover {
    color: #617487;
}

.panel-bon {
    border-color: #ce0757;
}

.panel-bon > .panel-heading {
    border-color: #ce0757;
    color: #fff;
    background-color: #ce0757;
}

.panel-bon > a {
    color: #ce0757;
}

.panel-bon > a:hover {
    color: #E25A91;
}

.panel-institution {
    border-color: #466baf;
}

.panel-institution > .panel-heading {
    border-color: #466baf;
    color: #fff;
    background-color: #466baf;
}

.panel-institution > a {
    color: #466baf;
}

.panel-institution > a:hover {
    color: #91A7CF;
}

.huge {
    font-size: 20px;

    line-height: 1em;
    letter-spacing: 0.025em;

}
.typeahead { z-index: 1051;}
.typeahead_wrapper { display: block; height: 30px; }
.typeahead_primary { font-weight: bold; }
.typeahead_secondary { font-size: .8em; margin-top: -5px; }


/*Instituion tree*/
.bold-text{
    font-weight: bold;
}

.association-box{
    color: #688636;
}

.degree-value{
    padding-left: 20px;
    font-size: small;
}

.quickFaseOut{
    position:absolute; background:white;
    width: 100%;
    color:#3c763d;
    border: 1px solid #3c763d;
    border-radius: 5%;
    margin-left: 5px;
    margin-top: 5px;
    padding: 3px 5px;
}

.quickFaseOutError{
    position:absolute; background:white;
    width: 100%;
    color:#E74C3C;
    border: 1px solid #E74C3C;
    border-radius: 5%;
    margin-left: 5px;
    margin-top: 5px;
    padding: 3px 5px;
}

#filterPageSize{
    width: 60px;
}
.degree-value :before{
    content: "\f19d"; /* FontAwesome Unicode */
    font-family: FontAwesome;
    display: inline-block;
    padding-right: 5px;
}

.just-padding {
    padding: 15px;
}

.list-group.list-group-root {
    padding: 0;
    overflow: hidden;
}

.list-group.list-group-root .list-group {
    margin-bottom: 0;
}

.list-group.list-group-root .list-group-item {
    border-radius: 0;
    border-width: 1px 0 0 0;
}

.list-group.list-group-root > .list-group-item:first-child {
    border-top-width: 0;
}

.list-group.list-group-root > .list-group > .list-group-item {
    padding-left: 40px;
}

.list-group.list-group-root > .list-group > .list-group > .list-group-item {
    padding-left: 70px;
}

.list-group-item a{
    text-decoration: none;
    color: #555;
}
.list-group-item .glyphicon{
    margin-right: 8px;
    padding: 1px 4px;
    border: 1px solid #555;
    border-radius: 100%;
    background-color: #555;
    color: #fff;

}

.list-group-item > button > .glyphicon{
    margin-right: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: #fff;
    color: #555;
}

.list-group-item > button{
    border: none;
}

.refineBody .radio{
    display: block;
}


/***********************/
/**General Tabs overwrite bootstrap css***/
/***********************/


.nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus{
    border: 1px solid #ddd;
    border-top: 3px solid orange;
    border-bottom: 1px solid #fff;
}

.institution-options-tags .nav-tabs>li.active>a,.institution-options-tags .nav-tabs>li.active>a:hover,.institution-options-tags .nav-tabs>li.active>a:focus{
    color: $brand-institution-light;
    border-top: none;
    background-color: #e6e6e6;
}
.nav-tabs>li>a {
    border-radius: 0;
    color: #888;
    font-weight: bold;
    padding: 10px 20px;
}

.nav-tabs>li>a:hover {
    border-color: #eee #eee #ddd;
}

.nav>li>a:hover, .nav>li>a:focus {
    text-decoration: none;
    color: inherit;
    background-color: transparent;
    border-color: transparent;
}

.panel-group .panel {    
    border-radius: 0;
    background-color: #eee;
}

.panel-default>.panel-heading {
    color: #333;
    background-color: #fff;
    border-color: #ddd;
    border-left: 3px solid orange;
}

.panel-heading>a{
    color: #555;
    font-weight: bold;
}
.panel-heading>a:hover {
    border-color: #eee #eee #ddd;
}

.panel-heading>a:hover, .panel-heading>a:focus {
    text-decoration: none;
    color: #bbb;
    background-color: transparent;
    border:0;
}


/**Side bar support.*/
.support{
    color: #fff;
    padding:12px 15px;
    text-align: left;
}
.support .inner h4{
    margin: 0;
    font-weight: bold;
    color: #fff;
}
.support .inner a{
    color: #fff;
    text-decoration: underline;
}
.support .inner a:hover{
    text-decoration: none;
}

.support .inner h4{
    margin: 10px 0 5px 0;
}

.support .inner p{
    font-size: small;
    text-align: justify;
}
.support i {
    float: left;
    padding-top: 12px;
    font-size: 30px;
    min-width: 45px;
}

/**blocks collors*/
.support.first {
    background: #696f77;
}
.support.second {
    background: #707780;
}
.support.third {
    background: #787f88;
}


/**Social box*/
.social:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
}
.social {
    margin: 0 5px;
    -webkit-transform: scale(0.9);
    /* Browser Variations: */     
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
}


.social-box .header{
    padding: 0 0 8px 5px;
    text-transform: uppercase;
    color: #2C3E50;
    font-weight: bold;
}
.social-box i{
    font-size: 20px;
    width: 40px;
    height: 40px;
    padding:10px 0;
    text-align: center;
    background-color: #BDC3C7;
    color: #2C3E50;
    border-radius: 5px;
}

#social-fb:hover {
    color: #3B5998;
}
#social-yt:hover {
    color: #cc181e;
}
#social-gp:hover {
    color: #d34836;
}
#social-em:hover {
    color: #f39c12;
}

.slogan{
    text-transform: uppercase;
    font-stretch: expanded;
    font-size: 130%;
    line-height: 1.5em;
    padding-top: 10px;
    padding-left: 10px;
}

/*
*    Notifications General Layout
*/
#notifications {
    min-width: 450px;
    max-width: 100%;
}

._nt-badge {
    border-radius: 1em;
    vertical-align: top;
    margin-left: 1px;
}

.notifications > ._nt-top {
    border-bottom: #CCCCCC solid 1px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 1px;
}

.notifications > ul {
    padding: 0;
    margin: 0;
}

.dropdown-menu .notifications > ul {
    max-height: 400px;
    overflow-y: auto;
}

.notifications > ul > li {
    list-style: none;
}

.notifications > ul > li._nt-unread {
    background-color: #e6e6f5;
}

.notifications > ul > li > a:not([href]){
    cursor: auto;
}

.notifications > ul > li > a {
    color: #333;
    display: block;
    border-bottom: #CCCCCC solid 1px;
    padding: 3px 5px 2px 10px;
}

.notifications > ul > li > a:hover {
    text-decoration: none;
}

.notifications > ul > li:hover {
    background-color: #d5d5eb;
}

/*
*    Notifications Item Layout
*/
.notifications > ul > li > a ._nt-col-1 {
    float: left;
    width: 35px;
    height: 35px;
    text-align: center;
}

.notifications > ul > li > a ._nt-col-2 {
    padding-left: 40px;
    padding-right: 10px;
    width: 100%;
    min-height: 35px;
}

.notifications > ul > li > div._nt-actions {
    width: 15px;
    display: none;
    text-align: center;
    float: right;
}

.notifications > ul > li > div._nt-actions > a {
    color: #5c6573;
}

.notifications > ul > li > div._nt-actions > a:hover {
    color: #3f434b;
}

.notifications > ul > li:hover > div._nt-actions {
    display: inherit;
}

.notifications > ul > li > a i._nt-icon {
    font-size: 2.5em;
}

.notifications > ul > li > a ._nt-time {
    float: right;
    line-height: 19px;
}

.notifications > ul > li > a ._nt-success {
    color: #5cb85c;
}

.notifications > ul > li > a ._nt-warning {
    color: #f0ad4e;
}

.notifications > ul > li > a ._nt-error {
    color: #d9534f;
}

.notifications > ul > li > a ._nt-info {
    color: #5bc0de;
}

.notifications > ul > li > a ._nt-primary {
    color: #337ab7;
}


/** New HOME PAGE
*/
.no-column-space [class^="col-"], .no-column-space [class*=" col-"] {
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
}

.g-text-color-1 {
    color: #2C3E50;
}
.g-theme-bg-color-1 {
    background-color: #2C3E50;
}

.g-text-color-2 {
    color: #eee;
}
.g-theme-bg-color-2 {
    background-color: #BDC3C7;
}
.arrow-section-block {
    position: relative;
    padding: 65px 40px;
    overflow: visible;
    border-bottom: 1px solid #BDC3C7;
}
.arrow-section-block:after {
    content: " ";
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -17px;
    border-width: 17px;
    border-style: solid;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
    z-index: 2;
    border-color: #fff;
}

@media (max-width: 767px) {
    .number-section {
        padding: 30px 0;
    }
    .arrow-section-block{
        padding: 45px 40px;
    }
}
.g-theme-bg-color-1 .arrow-section-block:after {
    border-color: #2C3E50;
}

.arrow-section-block.arrow-top:after {
    bottom: 100%;
    border-top-color: transparent;
}

.arrow-section-block.arrow-bottom:after {
    top: 100%;
    border-bottom-color: transparent;
}

#about h2, #partners h2{
    font-weight: bold;
    padding-bottom: 20px;
}

#statistics h2{
    font-weight: bold;
}

#about, #partners{
    padding: 80px 0 40px 0;
    color: #2C3E50;
}

#about p, .statistics-block-item p{
    line-height: 26px;
    font-size: large;
    font-weight: normal;
}

.arrow-section-block p {
    line-height: 26px;
    font-size: large;
}

#steps{
    margin-top: -20px;
}

#steps h3{
    margin-bottom: 30px;
    text-transform: uppercase;    
}


.statistics-block-item{
    margin-right: 0px;
    padding: 40px 40px 30px 40px;  
    transition: all 0.3s ease-in-out;
    width: 100%;  
    min-height: 255px;
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .statistics-block-item{           
        padding: 50px 40px 40px 40px;
        min-height: 225px;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .statistics-block-item{           
        padding: 50px 40px 40px 40px;
        min-height: 200px;
    }
}


/* Small devices (tablets, 992px and down) */
@media (max-width: 991px) {
    .sm-text-center{           
        text-align: center;
    }
}

.statistics-block-item a{
    font-size: small;
    text-decoration: underline;
}

.g-theme-bg-color-1 > div > div > .statistics-block-item a{
    color: #BEBEBE;
}
.g-theme-bg-color-1 > div > div > .statistics-block-item a:hover{
    color: #eee;
}

.g-theme-bg-color-2 > div > div > .statistics-block-item a{
    color: #42586E;
}
.g-theme-bg-color-2 > div > div > .statistics-block-item a:hover{
    color: #617487;
}

.g-theme-bg-color-1 > div > div > .statistics-block-item{
    border-bottom: solid 1px #1B3147;
}
.g-theme-bg-color-2 > div > div > .statistics-block-item{
    border-bottom: solid 1px #C6C7D0;
}

.g-theme-bg-color-1 > div > div > .statistics-block-item:hover{
    background-color: #1B3147;
}
.g-theme-bg-color-2 > div > div > .statistics-block-item:hover{
    background-color: #E9EAEB;
}

.stat-icon{
    width: 90px;
    height: 90px;
    border-radius: 60px;
}    
.stat-icon.orcid{
    font-size: 22px;
    color: #A6CE39;
    text-align: center;
    padding-top: 18px;
}
.g-theme-bg-color-1 > div > div > .statistics-block-item > div > div > .stat-icon {
    border: 3px solid #eee;
}
.g-theme-bg-color-2 > div > div > .statistics-block-item > div > div > .stat-icon {
    border: 3px solid #2C3E50;
}
.g-theme-bg-color-1 > div > div > .statistics-block-item > div > div > .stat-icon.orcid {
    border: 3px solid #A6CE39;
}

.orcid-text{
    color: #A6CE39;
}

.btn-stats{
    margin-top: 10px;
    border-radius: 0px;
    color: white;
    padding: 6px 12px;
    text-shadow: none;
    color: #fff;
    border: 1px solid #fff;
    text-decoration: none !important;
    min-width: 150px;
}



.btn-stats:hover{
    background-color: #fff;
    border: 1px solid #466baf;
    color: #2C3E50 !important;
    transition: all 300ms linear;

}

.btn-stats a{
    text-transform: none;
}

.number-section{
    background-color: #BDC3C7;
    padding: 50px 0;
    color: #2C3E50;
}

.carousel.slide img {
    width:80%;
    height:auto;
}
.carousel-control.left,.carousel-control.right {background-image:none;}
.rightest{ visibility: visible;}

/**************************
    institution-options-tags
*/

.institution-options-tags-buttons a{
    margin: 3px 8px 8px 8px;
    font-weight: bold;
    line-height: 20px;
    text-transform: uppercase;
}

.bg-whitesmoke {
    background-color: #f5f5f5;
}

.embeddedFilters {
    background-color: white;
    margin-bottom: 10px;
}

.embeddedFilters label {
    font-size: small;
}

@media (min-width: 768px) {
    .embeddedContent > .subcontent{
        border-left: 3px solid #cccccc;
    }
}

.inside-table > tbody > tr:first-child > td{
    border: none;
}



/**
Icon tabs.
*/

.icon-box-tabs .nav-tabs{
    border-bottom: none;

}
.icon-box-tabs .nav-tabs>li.active>a, .icon-box-tabs .nav-tabs>li.active>a:hover, .icon-box-tabs .nav-tabs>li.active>a:focus{
    border: none;

}

.icon-box-tabs .nav>li>a:hover,  .icon-box-tabs.nav>li>a:focus {
    text-decoration: none;
    color: inherit;
    background-color: transparent;
    border-color: transparent;
}

.icon-box-tabs .nav-tabs>li>a {
    border-radius: 0;
    background: rgba(34, 34, 34, .05);
    color: #434343;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.icon-box-tabs .nav-tabs>li>a:hover:after, .icon-box-tabs .nav-tabs>li.active>a:after, .icon-box-tabs .nav-tabs>li.active>a:focus:after, .icon-box-tabs .nav-tabs>li.active>a:hover:after {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    opacity: 1;
}
.icon-box-tabs .nav-tabs>li>a:after {
    opacity: 0;
    bottom: -10px;
    content: "";
    left: 50%;
    margin-left: -10px;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #BDC3C7 transparent transparent transparent;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.icon-box-tabs .nav-tabs>li>a i {
    font-size: 26px;
    padding-right: 10px;
}

.icon-box-tabs .nav>li>a {
    padding: 20px 20px;
}
.icon-box-tabs .nav>li>a>span {
    position: relative;
    top: -8px;
}
.icon-box-tabs .nav-tabs>li>a:hover, .icon-box-tabs .nav-tabs>li.active>a, .icon-box-tabs .nav-tabs>li.active>a:focus, .icon-box-tabs .nav-tabs>li.active>a:hover{
    color: #222;
    background-color: #BDC3C7;
}
@media screen and (max-width:991px) and (min-width: 768px) {
    .icon-box-tabs .nav>li>a {
        padding: 35px 40px;

    }
}

@media screen and (max-width: 767px) {
    .icon-box-tabs .nav>li>a {
        padding: 35px 30px;
    }
}

.authors{
  margin-top: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: rgba(34, 34, 34, .05);
}
.authors-count-container {
  text-align: center;
  border-right: 1px solid #B7B7B7;
}
.authors-count-container .authors-count {
  font-size: 1.2em;
  font-weight: 700;
}
.authors-flex{
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mainmeta{
  padding-top: 5px;
  padding-bottom: 5px;
  background: rgba(34, 34, 34, .1);
}

.icon-box-tabs .tab-pane{
  padding-top: 20px;
}

.icon-box-tabs .tab-pane .row{
  margin-left: 0px;
  margin-right: 0px;
}

.box-full {
  border-style: solid;
  border-width: 1px;
  border-color: #ececec;
  margin-bottom: 10px;
}

.box-body {
  padding: 5px;
}

.box-header {
  background-color: rgb(242,243,246);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(240,240,240);
  padding: 6px;
  padding-left: 15px;
  font-size: 15px;
  font-weight: 600;
}

.box-button-toggle {
    color: #bbbbbb;
    padding: 3px;
    border: none;
    background: none;
}
.statistic-line{
  padding: 6px 0;
}

.div-heading{
    background-color:rgba(34, 34, 34, 0.05);
    padding: 5px 15px;
}
.div-body{
    padding: 5px 15px;
}
.div-heading a{
    color: grey;
    font-weight: bold;
    text-decoration: none;
}
.div-heading a:hover{
    color: #3097D1;
}
.div-heading a:after {
    font-family:'Glyphicons Halflings';
    content:"\e114";
    float: right;
    color: grey;
}
.div-heading a.collapsed:after {
    content:"\e080";
}

.publication-affiilations a.parent{
    font-size: small;
}
.publication-affiilations a.current{
    font-weight: bold;
}
.subfield{
  padding-left: 30px;
  padding-bottom: 5px;
}
.mainfield{
  font-weight: bold;
  padding-bottom: 5px;
  font-size: large;
}
.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}