
// Body
$body-bg: #ffffff;

// Borders
$cakephp-border-color: darken($body-bg, 10%);
$list-group-border: $cakephp-border-color;
$navbar-default-border: $cakephp-border-color;
$panel-default-border: $cakephp-border-color;
$panel-inner-border: $cakephp-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #27ae60;
$brand-warning: #f1c40f;
$brand-danger:  #bf5329;

//Main Authetnticus colors.
$authenticus-dark-blue: #2C3E50; //#1B3147
$authenticus-grey: #BDC3C7;

//Main institutions colors
$brand-institution-light: #f5f5f5; //clouds
$brand-institution-dark: #BDC3C7; //silver

//Main researchers colors

$border-radius-base:        0px;
$border-radius-large:       0px;
$border-radius-small:       0px;

// Typography
$font-family-sans-serif: "Gadget", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.3;
$text-color: #555;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;
